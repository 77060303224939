var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",attrs:{"id":"questions-section"}},[_c('h2',{staticClass:"section-title--double"},[_vm._v("Chcesz dowiedzieć się więcej?")]),_c('section-title',{attrs:{"text":'Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania'}}),_c('div',{attrs:{"id":"questions"}},[_c('ol',{staticClass:"question-list"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Jaki jest koszt finansowania?")]),_c('details',[_vm._m(6),_vm._m(7),_c('button-open-dialog',{attrs:{"addingClass":"button-text"}})],1)]),_vm._m(8),_vm._m(9)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Jak działa brutto.pl?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Brutto.pl to serwis internetowy, za pomocą którego możesz złożyć wniosek o finansowanie dla firm, przejść proces weryfikacji, uzyskać ofertę i podpisać umowę. Gdy to zrobisz – na Twoje konto trafią środki z finansowania.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Kto może ubiegać się o finansowanie dla firm?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Z oferty mogą skorzystać wszystkie podmioty, które korzystają na co dzień z Płatności Online Autopay i zarejestrują się w serwisie brutto.pl. Dotyczy to niemal wszystkich form prawnych, za wyjątkiem fundacji, stowarzyszeń i rolników (szczegóły znajdziesz w "),_c('a',{staticClass:"text-link",attrs:{"href":"https://www.brutto.pl/regulamin/","title":"Przejdź do strony z regulaminem"}},[_vm._v("regulaminie usługi")]),_vm._v("). Jedynym warunkiem jest minimum 3‑miesięczna historia transakcji zrealizowanych za pośrednictwem Płatności Online Autopay oraz prowadzenie firmy od co najmniej 12 miesięcy. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Jak sprawdzić możliwą kwotę finansowania?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Kliknij przycisk \"Chcę bezpłatnie poznać ofertę\", a przekierujemy Cię na stronę brutto.pl. Tam, po rejestracji, uzyskasz informacje o możliwej maksymalnej kwocie finansowania dla Twojej firmy.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Jakie dane są potrzebne do złożenia wniosku?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Serwis brutto.pl potrzebuje jedynie informacji o wysokości sprzedaży, jaką generuje Twój serwis internetowy, pod który masz podpięte Płatności Online Autopay. Taka informacja zostanie automatycznie przekazana do brutto.pl w momencie Twojej rejestracji. Podczas akceptacji umowy w serwisie brutto.pl zostaniesz poproszony o podanie kwoty z ostatniej faktury VAT otrzymanej od Autopay. To wszystko.")]),_c('p',[_vm._v("Brutto.pl nie wymaga poręczeń osobistych i żyrantów.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Na co można przeznaczyć środki z finansowania?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Możesz je przeznaczyć na dowolny cel związany z działalnością firmy, np. na: zakup towaru, podatki, pensje pracowników lub działania sprzedażowo-marketingowe.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Po jakim czasie otrzymuje się środki?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Środki są przekazywane za pomocą przelewu ekspresowego w dniu, w którym akceptujesz ofertę finansowania i podpisujesz umowę kodem SMS. W związku z tym, że cały proces odbywa się online – zajmuje zazwyczaj max. jeden dzień.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Koszt zależy od wysokości uzyskanego finansowania. Dokładną kwotę poznasz po złożeniu wniosku online, gdy brutto.pl przygotuje ofertę dopasowaną do Twoich potrzeb i wyników sprzedażowych. Przybliżone koszty możesz poznać korzystając z kalkulatora lub "),_c('a',{staticClass:"text-link",attrs:{"href":"#simulation-section","title":"Przejdź do sekcji z symualcją"}},[_vm._v("symulacji widocznych na stronie powyżej")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Jak odbywa się spłata?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Raty będą pobierane automatycznie z Twojego salda w systemie Autopay (PayAutopay) jako - wybrany przez Ciebie na etapie zawierania umowy - stały procent Twojej sprzedaży wypracowany za pośrednictwem Płatności Online Autopay. Tak więc wysokość dziennej raty będzie zależeć od aktualnej sprzedaży. Oznacza to, że w przypadku spadku sprzedaży pobierane przez nas kwoty spadną (lub nawet zatrzymają się) a w przypadku wzrostu sprzedaży szybciej spłacisz otrzymane finansowanie. Minimalny poziom automatycznych spłat w czasie trwania pożyczki wynosi natomiast 1/9 kwoty pożyczki co każde 45 dni.")]),_c('p',[_vm._v("Za operację pobierania spłat odpowiada grupa PragmaGo, w ramach której działa serwis brutto.pl - dlatego w trakcie składania wniosku należy wyrazić zgodę na udzielenie pełnomocnictwa właśnie tej firmie. Z udzielonego pełnomocnictwa można się wycofać, ale z umowy na finansowanie już nie - w takiej sytuacji spłaty nie będą już pobierane z salda w systemie Autopay (PayAutopay), lecz będzie wymagana natychmiastowa spłata pozostałej kwoty pożyczki.")]),_c('p',[_vm._v("Na bazie Twojej historii sprzedaży, brutto.pl zakłada, że spłacisz należność w ciągu 12 miesięcy, ale dodatkowo daje Ci bezpłatny miesiąc na spłatę. Maksymalny okres spłaty wynosi zatem aż 13 miesięcy.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-box"},[_c('span',[_vm._v("Czy można zrezygnować z usługi?")]),_c('details',[_c('summary',[_c('span',{staticClass:"summary--open"},[_vm._v("Pokaż ")]),_c('span',{staticClass:"summary--close"},[_vm._v("Ukryj ")]),_vm._v("odpowiedź")]),_c('p',[_vm._v("Z usługi finansowania można zrezygnować do momentu podpisania umowy, później jest to już niemożliwe. Możliwa jest natomiast spłata całości finansowania w dowolnym momencie trwania pożyczki. Wcześniejsza spłata nie wpływa natomiast na całkowite koszty finansowania.")])])])
}]

export { render, staticRenderFns }