<template>
  <section id="questions-section" class="section">
    <h2 class="section-title--double">Chcesz dowiedzieć się więcej?</h2>
    <section-title :text="'Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania'" />
    <div id="questions">
      <ol class="question-list">

        <li class="question-box">
          <span>Jak działa brutto.pl?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Brutto.pl to serwis internetowy, za pomocą którego możesz złożyć wniosek o finansowanie dla firm, przejść proces weryfikacji, uzyskać ofertę i podpisać umowę. Gdy to zrobisz – na Twoje konto trafią środki z finansowania.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Kto może ubiegać się o finansowanie dla firm?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Z oferty mogą skorzystać wszystkie podmioty, które korzystają na co dzień z Płatności Online Autopay i zarejestrują się w serwisie brutto.pl. Dotyczy to niemal wszystkich form prawnych, za wyjątkiem fundacji,
              stowarzyszeń i rolników (szczegóły znajdziesz w
              <a class="text-link" href="https://www.brutto.pl/regulamin/" title="Przejdź do strony z regulaminem">regulaminie usługi</a>). Jedynym warunkiem jest minimum 3&#8209;miesięczna historia transakcji zrealizowanych za pośrednictwem Płatności Online Autopay oraz prowadzenie firmy od co najmniej 12 miesięcy.
            </p>
          </details>
        </li>

        <li class="question-box">
          <span>Jak sprawdzić możliwą kwotę finansowania?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Kliknij przycisk &quot;Chcę bezpłatnie poznać ofertę&quot;, a przekierujemy Cię na stronę brutto.pl. Tam, po rejestracji, uzyskasz informacje o możliwej maksymalnej kwocie finansowania dla Twojej firmy.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Jakie dane są potrzebne do złożenia wniosku?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Serwis brutto.pl potrzebuje jedynie informacji o wysokości sprzedaży, jaką generuje Twój serwis internetowy, pod który masz podpięte Płatności Online Autopay. Taka informacja zostanie automatycznie przekazana do brutto.pl w momencie Twojej rejestracji. Podczas akceptacji umowy w serwisie brutto.pl zostaniesz poproszony o podanie kwoty z ostatniej faktury VAT otrzymanej od Autopay. To wszystko.</p>
            <p>Brutto.pl nie wymaga poręczeń osobistych i żyrantów.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Na co można przeznaczyć środki z finansowania?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Możesz je przeznaczyć na dowolny cel związany z działalnością firmy, np. na: zakup towaru, podatki, pensje pracowników lub działania sprzedażowo-marketingowe.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Po jakim czasie otrzymuje się środki?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Środki są przekazywane za pomocą przelewu ekspresowego w dniu, w którym akceptujesz ofertę finansowania i podpisujesz umowę kodem SMS. W związku z tym, że cały proces odbywa się online – zajmuje zazwyczaj max. jeden dzień.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Jaki jest koszt finansowania?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Koszt zależy od wysokości uzyskanego finansowania. Dokładną kwotę poznasz po złożeniu wniosku online, gdy brutto.pl przygotuje ofertę dopasowaną do Twoich potrzeb i wyników sprzedażowych. Przybliżone koszty możesz poznać korzystając z kalkulatora lub
              <a class="text-link" href="#simulation-section" title="Przejdź do sekcji z symualcją">symulacji widocznych na stronie powyżej</a>.
            </p>
            <button-open-dialog addingClass="button-text"/>
          </details>
        </li>

        <li class="question-box">
          <span>Jak odbywa się spłata?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Raty będą pobierane automatycznie z Twojego salda w&nbsp;systemie Autopay (PayAutopay) jako - wybrany przez Ciebie na etapie zawierania umowy - stały procent Twojej sprzedaży wypracowany za pośrednictwem Płatności Online Autopay. Tak więc wysokość dziennej raty będzie zależeć od aktualnej sprzedaży. Oznacza to, że w przypadku spadku sprzedaży pobierane przez nas kwoty spadną (lub nawet zatrzymają się) a w przypadku wzrostu sprzedaży szybciej spłacisz otrzymane finansowanie. Minimalny poziom automatycznych spłat w&nbsp;czasie trwania pożyczki wynosi natomiast 1/9&nbsp;kwoty pożyczki co każde 45&nbsp;dni.</p>
            <p>Za operację pobierania spłat odpowiada grupa PragmaGo, w ramach której działa serwis brutto.pl - dlatego w trakcie składania wniosku należy wyrazić zgodę na udzielenie pełnomocnictwa właśnie tej firmie. Z udzielonego pełnomocnictwa można się wycofać, ale z umowy na finansowanie już nie - w takiej sytuacji spłaty nie będą już pobierane z salda w&nbsp;systemie Autopay (PayAutopay), lecz będzie wymagana natychmiastowa spłata pozostałej kwoty pożyczki.</p>
            <p>Na bazie Twojej historii sprzedaży, brutto.pl zakłada, że spłacisz należność w ciągu 12 miesięcy, ale dodatkowo daje Ci bezpłatny miesiąc na spłatę. Maksymalny okres spłaty wynosi zatem aż 13 miesięcy.</p>
          </details>
        </li>

        <li class="question-box">
          <span>Czy można zrezygnować z usługi?</span>
          <details>
            <summary><span  class="summary--open">Pokaż </span><span class="summary--close">Ukryj </span>odpowiedź</summary>
            <p>Z usługi finansowania można zrezygnować do momentu podpisania umowy, później jest to już niemożliwe. Możliwa jest natomiast spłata całości finansowania w dowolnym momencie trwania pożyczki. Wcześniejsza spłata nie wpływa natomiast na całkowite koszty finansowania.</p>
          </details>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import SectionTitle from '../common/SectionTitle'
import ButtonOpenDialog from '../common/ButtonOpenDialog'

export default {
  name: 'QuestionsSection',
  components: {
    ButtonOpenDialog,
    SectionTitle
  }
}
</script>
