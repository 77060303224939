<template>
  <section id="hero-section" class="section">
    <div id="hero" class="grid-container">
      <article class="item1">
        <h1 class="page-title">Skorzystaj z finansowania i&nbsp;przyspiesz rozwój swojej firmy</h1>
        <ul class="article-list">
          <li><p class="article-text">cały proces 100% online</p></li>
          <li><p class="article-text">maksymalna kwota finansowania: 150&nbsp;000&nbsp;PLN</p></li>
          <li><p class="article-text">oferta dla firm korzystających z&nbsp;Płatności Online Autopay</p></li>
        </ul>
        <p class="article-text">Już teraz sprawdź dostępny dla Ciebie limit i&nbsp;uzyskaj ofertę finansowania.</p>
        <button-open-dialog addingClass="hero-button"/>
      </article>
      <article class="item2">
        <div class="hero-logo-container">
          <img class="hero-logo" src="@/assets/images/Brutto_Grupa_4.png" alt="Logo Brutto">
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import ButtonOpenDialog from '../common/ButtonOpenDialog'

export default {
  name: 'HeroSection',
  components: {
    ButtonOpenDialog
  }
}
</script>
