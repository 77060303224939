<template>
  <section id="specificity-section" class="section">
    <section-title :text="'Co wyróżnia ofertę brutto.pl?'" />
    <div id="infos-2" class="grid-container">
      <article class="item1 info-box">
        <h3 class="article-title">Szybki proces</h3>
        <p class="article-text">Cały proces odbywa się w 100% online, a&nbsp;na&nbsp;decyzję o przyznaniu finansowania wpływają Twoje wyniki sprzedażowe, które weryfikujemy na&nbsp;podstawie danych z&nbsp;konta w&nbsp;systemie Autopay (PayAutopay).</p>
        <img class="info-icon" src="@/assets/images/icon_60px_1_blue.svg" alt="">
      </article>
      <article class="item2 info-box">
        <h3 class="article-title">Błyskawiczna wypłata środków</h3>
        <p class="article-text">W&nbsp;dniu podpisania umowy kodem SMS przekażemy finansowanie na&nbsp;Twój rachunek bankowy podany w&nbsp;systemie Autopay (PayAutopay). Wpłata zostanie zrealizowana przelewem ekspresowym, dzięki czemu środki będą od&nbsp;razu do&nbsp;Twojej dyspozycji.</p>
        <img class="info-icon" src="@/assets/images/icon_60px_45_blue.svg" alt="">
      </article>
      <article class="item3 info-box">
        <h3 class="article-title">Wygodna spłata</h3>
        <p class="article-text">Na&nbsp;poczet spłaty finansowania pobierany jest stały procent od&nbsp;sprzedaży, aż&nbsp;do momentu osiągnięcia pełnej kwoty. Jeśli w&nbsp;danym miesiącu zarobisz mniej, Twoja rata również będzie niższa. Na&nbsp;spłatę masz aż 13&nbsp;miesięcy.</p>
        <img class="info-icon" src="@/assets/images/icon_60px_3_orange.svg" alt="">
      </article>
    </div>
    <button-open-dialog />
  </section>
</template>

<script>
import SectionTitle from '../common/SectionTitle'
import ButtonOpenDialog from '../common/ButtonOpenDialog'

export default {
  name: 'InfosSpecificitySection',
  components: {
    ButtonOpenDialog,
    SectionTitle
  }
}
</script>
