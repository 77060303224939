<template>
  <section id="process-section" class="section">
    <section-title :text="'Jak przebiega proces finansowania?'" />
    <div id="infos-3" class="grid-container">
      <article class="item1">
        <div class="info-heading">
          <h3 class="article-title">1</h3><hr/>
        </div>
        <h3 class="article-title">Analiza</h3>
        <p class="article-text">Integracja pomiędzy brutto.pl a&nbsp;Twoim kontem w&nbsp;systemie Autopay (PayAutopay) pozwoli zweryfikować Twoje wyniki sprzedażowe i&nbsp;na&nbsp;ich podstawie określić maksymalną kwotę finansowania.</p>
      </article>
      <article class="item2">
        <div class="info-heading">
          <h3 class="article-title">2</h3><hr/>
        </div>
        <h3 class="article-title">Wybór oferty</h3>
        <p class="article-text">Wskaż wysokość finansowania, które chcesz uzyskać, zapoznaj&nbsp;się z&nbsp;warunkami oferty i&nbsp;kosztami. Potwierdź chęć zawarcia umowy, żeby otrzymać potrzebne środki.</p>
      </article>
      <article class="item3">
        <div class="info-heading">
          <h3 class="article-title">3</h3><hr/>
        </div>
        <h3 class="article-title">Wypłata środków</h3>
        <p class="article-text">Finansowanie trafi na&nbsp;rachunek bankowy (wskazany na&nbsp;Twoim koncie w&nbsp;systemie Autopay (PayAutopay)) w&nbsp;dniu, w&nbsp;którym podpiszesz umowę.</p>
      </article>
      <article class="item4">
        <div class="info-heading">
          <h3 class="article-title">4</h3><hr/>
        </div>
        <h3 class="article-title">Spłata</h3>
        <p class="article-text">Raty będą pobierane automatycznie z&nbsp;Twojego salda w&nbsp;PayAutopay, a&nbsp;ich wysokość będzie zależeć od&nbsp;aktualnej sprzedaży. Na&nbsp;całkowitą spłatę finansowania masz 13 miesięcy.</p>
      </article>
    </div>
    <button-open-dialog />
  </section>
</template>

<script>
import SectionTitle from '../common/SectionTitle'
import ButtonOpenDialog from '../common/ButtonOpenDialog'

export default {
  name: 'InfosProcessSection',
  components: {
    ButtonOpenDialog,
    SectionTitle
  }
}
</script>
